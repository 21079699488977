import React from 'react'
//Types
import { SectionAboutStory } from '../../../types/sections'
//Components
import { Box } from '../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { ContentMd } from '../../commons'

export interface AboutStoryProps {
  data: SectionAboutStory
}

const AboutStory: React.FC<AboutStoryProps> = ({ data }) => {
  const { id, sectionId, content } = data
  return (
    <Box as={'section'} id={sectionId || id} className={'p-section'} my={[80, 80, 120]}>
      <Grid>
        <Row center={'xs'}>
          <Col xs={12} md={10} lg={8}>
            <ContentMd content={content} />
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default AboutStory
